import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from "react-router-dom";

import registerServiceWorker from "./registerServiceWorker";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SplashScreen from "./screens/SplashScreen/SplashScreen";
import IntroScreen from "./screens/IntroScreen/IntroScreen";
import CardScreen from "./screens/CardScreen/CardScreen";
import OptionScreen from "./screens/OptionScreen/OptionScreen";
import ResultScreen from "./screens/ResultScreen/ResultScreen";
import ExitScreen from "./screens/ExitScreen/ExitScreen";

import "./index.scss";

import themas from "./data/themas.js";
import teksten from "./data/teksten.js";


class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			themas: themas,
			teksten: teksten
		};
	}

	componentDidMount() {
		this.resetScore();
	}

	resetScore = () => {
		const { themas } = this.state;
		themas.map(thema => {
			thema.checked = false;
			return thema.straten.map(straat =>
				straat.stegen.map(steeg => {
					steeg.score = null;
					return steeg;
				})
			)
		});
		this.setState({ themas });
	};


	updateScore = (id, score) => {
		const { themas } = this.state;
		themas.map(thema => {
			return thema.straten.map(straat =>
				straat.stegen.map(steeg => {
					if (steeg.id === id) steeg.score = score;
					return steeg;
				})
			)
		});
		this.setState({ themas });
	};

	checkTheme = id => {
		const { themas } = this.state;
		themas.map(thema => {
			if (+thema.id === id) thema.checked = true;
			return thema;
		});
		this.setState({ themas });
	};

	toggleModal = () => {
		this.setState({ modal: !this.state.modal });
	};

	toggleMailModal = () => {
		this.setState({ mailmodal: !this.state.mailmodal });
	};

	render() {
		const { themas } = this.state;
		const checked = themas.filter(thema => thema.checked);
    const allChecked = checked.length === 4;

		return (
			<Router>
				<ScrollToTop>
					<Switch>
						<Route
							exact
							path="/"
							render={() => <SplashScreen resetScore={this.resetScore} />}
						/>
						<Route
							path="/inleiding"
							render={props => (
								<IntroScreen
									{...props}
								/>
							)}
						/>

						<Route
							path="/themas"
							render={props => (
								<CardScreen
									data={this.state}
									toggleModal={this.toggleModal}
									checkTheme={this.checkTheme}
									{...props}
								/>
							)}
						/>
						<Route
							path="/stellingen/:thema"
							render={props => (
								<OptionScreen
									data={this.state}
									updateScore={this.updateScore}
									checkTheme={this.checkTheme}
									toggleModal={this.toggleModal}
									{...props}
								/>
							)}
						/>
						<Route
							path="/resultaat"
							render={props => allChecked
								? <ResultScreen
									data={this.state}
									toggleModal={this.toggleModal}
									toggleMailModal={this.toggleMailModal}
									{...props}
								/>
								: <Redirect to={{ pathname: "/themas" }} />
							}
						/>
						<Route
							path="/acties/:actie"
							render={props => allChecked
								? <ExitScreen
									data={this.state}
									toggleModal={this.toggleModal}
									toggleMailModal={this.toggleMailModal}
									resetScore={this.resetScore}
									{...props}
								/>
								: <Redirect to={{ pathname: "/themas" }} />
							}
						/>

						<Route render={() => <Redirect to={{ pathname: "/" }} /> } />
					</Switch>
				</ScrollToTop>
			</Router>
		);
	}
}

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
