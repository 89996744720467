import React from 'react';

import Kruis from '../Icons/Kruis';

import './Modal.scss';

const Modal = (props) => {

	const { kop, tekst } = props.teksten.colofon;

	return (
		<div className={props.open ? "modal open" : "modal"}>
			<div className="overlay" onClick={props.toggleModal} />
		  <div className="panel">
            <div className='title-bar'>
                <h3>
                    {kop}
                </h3>
                <div className='close-button' onClick={props.toggleModal}>
                    <Kruis />
                </div>
            </div>

		    <div className="content">
		    	{tekst}
		    </div>
		  </div>
	  </div>
	)
}

export default Modal;
