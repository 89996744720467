import React from 'react';
import { Link } from 'react-router-dom';

import Vinkje from '../Icons/Vinkje';
import Button from '../Button/Button'

import './Card.scss';

const Card = (props) => {

	const { thema } = props;
	const showVinkje = thema.checked ? <Vinkje /> : null;

	return (
		<Link to={'/stellingen/thema'+ props.thema.id} >
			<div className={"card card" + props.thema.id}  >
				<h5>{props.thema.naam}</h5>
				{showVinkje}
				<Button 
					designType={thema.checked ? 'secondary-ghost' : 'secondary'}
					text={thema.checked ? "Bewerken" : "Invullen"}
					icon='pijl'
					iconPosition='left'
				/>
			</div>
		</Link>
	)
}

export default Card;
