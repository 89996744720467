import React from 'react';

const Mail = ({ color = '#FFFFFF' }) => (
	<svg viewBox="7.5 7.5 30 30" className="icon print">
    <rect x="12" y="19" width="20" height="8" rx="1" ry="1" fill={color} />
    <path d="M18,13h8a1,1,0,0,1,1,1v3.5a0,0,0,0,1,0,0H17a0,0,0,0,1,0,0V14A1,1,0,0,1,18,13Z" fill={color} />
    <path className="lade" d="M18,30.75A1.75,1.75,0,0,1,16.25,29V24.25h11.5V29A1.75,1.75,0,0,1,26,30.75Z" fill={color} strokeWidth="1.5" />
	</svg>
);

export default Mail;

