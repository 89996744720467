import React from 'react';
import pdf from '../pdf/apg-argumentenkaart-werkgevers.pdf';

let pdfurl = pdf[0] !== '.' ? pdf : pdf.substring(1);

if (window.location.host.indexOf('gitlab') > -1) {
	pdfurl = "https://argumentenfabriek.gitlab.io/keuzehulp-pensioen-werkgevers" + pdfurl;
}

export default {
	colofon: {
		kop: 'Hoe is deze app tot stand gekomen?',
		tekst: <div>
			<p>Deze Gesprekshulp helpt u om goede beslissingen te nemen rondom de laatste carrièrefase van uw oudere werknemers. De Gesprekshulp geeft u inzicht in argumenten die relevant zijn voor u als werkgever. Hiervoor leggen we u een aantal stellingen voor. Aan het einde van de app ziet u wat u het beste kunt doen.</p>

			<p>Deze app is gemaakt door <a href='https://www.argumentenfabriek.nl' target='_blank' rel='noopener noreferrer'>De Argumentenfabriek</a> in opdracht van <a href='https://www.apg.nl/nl' target='_blank' rel='noopener noreferrer'>APG</a>. Er is een app voor werknemers en voor werkgevers. Naast deze apps heeft De Argumentenfabriek twee Argumentenkaarten gemaakt. Eén kaart bevat een overzicht van mogelijke afwegingen als u als werkgever twijfelt wat de beste optie is voor uw oudere werknemer: doorwerken of stoppen. De tweede Argumentenkaart bevat afwegingen van werknemers zelf, wanneer zij twijfelen tussen doorwerken of met pensioen gaan. De afwegingen in de apps komen uit deze Argumentenkaarten.</p>
			</div>
		},
	doorgaan: {
		kop: 'U wilt doorgaan met deze medewerker',
		tekst1: 'Willen zowel u als uw medewerker graag door? Fijn!',
		acties: [{
			kop: 'Duurzame Inzetbaarheid verbeteren',
			tekst: (
				<div>
				    <p>Een gesprek over ‘de laatste werkfase’ is misschien niet urgent, maar het is altijd goed om op de hoogte te blijven van elkaars verwachtingen. Informeer elkaar hier af en toe over.</p>
						<p>Hou ook in de gaten of de (oudere) werknemer mentaal en fysiek fit genoeg blijft om z'n werk goed te kunnen doen. Hoe eerder u ontdekt dat een werknemer tegen zijn grenzen aanloopt, des te makkelijker het is om een passende oplossing te vinden. </p>
				</div>
				)
		}],
		tekst2: 'Hieronder treft u de argumenten aan die u belangrijk vindt.'
	},
	anders: {
		kop: 'U wilt dat deze medewerker anders gaat werken',
		tekst1: 'Dit kunt u doen.',
		acties: [{
			kop: 'Duurzame Inzetbaarheid verbeteren',
			tekst: (
				<div>
				    <p>Ga in gesprek met uw medewerker. Mogelijke onderwerpen zijn:</p>
						<ul>
							<li>Investeren in (andere) kennis en vaardigheden</li>
							<li>Het belang van een goede fysieke en mentale gezondheid (stresspreventie)</li>
							<li>Het verbeteren van de werkomgeving</li>
						</ul>
						<p>Heeft u beiden alle mogelijkheden op een rijtje? Bespreek dan gezamenlijk wat de meest wenselijke optie is. Gebruik hiervoor <a href={pdfurl} rel="noopener noreferrer" target="_blank" >de Argumentenkaart</a>. De Argumentenkaart is een overzicht van overwegingen die een rol (kunnen) spelen bij de keuze tussen doorwerken, stoppen, of op een andere manier gaan werken.</p>
				</div>
				)
		},
		{
			kop: 'Medewerker gaat ander werk doen',
			tekst:(
				<div>
					<p>Ga in gesprek met uw medewerker. Mogelijke onderwerpen zijn:</p>
					<ul>
						<li>Andere functie-inhoud</li>
						<li>Andere werkwijze</li>
						<li>Demotie</li>
						<li>Flexibele werktijden</li>
						<li>Deeltijdwerken, eventueel aangevuld met ABP Keuzepensioen</li>
						<li>Generatiepact</li>
						<li>Detacheren, outplacement, freelance werken</li>
					</ul>
					<p>Heeft u beiden alle mogelijkheden op een rijtje? Bespreek dan gezamenlijk wat de meest wenselijke optie is. Gebruik hiervoor <a href={pdfurl} rel="noopener noreferrer" target="_blank" >de Argumentenkaart</a>. De Argumentenkaart is een overzicht van overwegingen die een rol (kunnen) spelen bij de keuze tussen doorwerken, stoppen, of op een andere manier gaan werken.</p>
				</div>
				)
		}
		],
		tekst2: 'Hieronder treft u de argumenten aan die u belangrijk vindt.'
	},
	stoppen: {
		kop: 'U wilt afscheid nemen van deze medewerker.',
		tekst1: 'Dit kunt u doen.',
		acties: [{
				kop: 'Financiële afwikkeling',
				tekst: (
				<div>
	        <p>Ga in gesprek met uw medewerker. Mogelijke onderwerpen zijn:</p>
          <ul>
						<li>ABP-keuzepensioen en/of generatiepact</li>
						<li>Vertrekregeling</li>
						<li>Stoppen met werken, met behoud van salaris</li>
						<li>WW-uitkering</li>
          </ul>
					<p>Heeft u beiden alle mogelijkheden op een rijtje? Bespreek dan gezamenlijk wat de meest wenselijke optie is. Gebruik hiervoor <a href={pdfurl} rel="noopener noreferrer" target="_blank" >de Argumentenkaart</a>. De Argumentenkaart is een overzicht van overwegingen die een rol (kunnen) spelen bij de keuze tussen doorwerken, stoppen, of op een andere manier gaan werken.</p>
				</div>
				)
			},
			{
				kop: 'Organisatorische afwikkeling',
				tekst:(
					<div>
				    <p>Bereid vervanging voor</p>
            <ul>
							<li>Interne vervanging</li>
							<li>Externe vervanging (denk aan verjonging)</li>
            </ul>
					</div>
				)
			}
		],
		tekst2: 'Hieronder treft u de argumenten aan die u belangrijk vindt.'
	}
}
