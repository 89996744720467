import React, { Component } from "react";

import Straat from "./Straat";

import "./Harmonica.scss";

class Harmonica extends Component {
	state = { open: [] };

	componentDidMount() {
		const { thema } = this.props;
		const { open } = this.state;
		const firstStreet = thema.straten[0].id;
		open.push(firstStreet);
		this.setState({ open });
	}

	handleClick = id => {
		const { open } = this.state;
		const index = open.indexOf(id);
		if (index === -1) open.push(id);
		else open.splice(index, 1);
		this.setState({ open });
	}

	render() {
		const { thema } = this.props;
		const { open } = this.state;

		const straten = thema.straten.map(straat => (
			<Straat
				key={"straat" + straat.id}
				straat={straat}
				handleClick={this.handleClick}
				open={open.indexOf(straat.id) > -1}
				{...this.props}
			/>
		));

		return <div className={"harmonica harmonica" + thema.id}>{straten}</div>;
	}
}
export default Harmonica;
