import React from "react";
import { Link } from "react-router-dom";

import Header from "../../components/Header/Header";
import ShareButtons from "../../components/ShareButtons/ShareButtons";
import Button from "../../components/Button/Button";

import "./IntroScreen.scss";

const IntroScreen = props => {
	return (
		<div className="introscreen">
			<Header {...props} />
			<ShareButtons />
			<div className="wrapper">
				<h1>Inleiding Gesprekshulp</h1>
				<p>
					De AOW-leeftijd gaat gestaag omhoog en steeds meer werknemers moeten
					dus langer doorwerken. Maar hoe zorgt u ervoor dat uw werknemers op
					een gezonde manier hun pensioenleeftijd halen? En hoe bewaakt u de
					balans tussen de aanwas van jong talent en het behouden van
					belangrijke kennis en ervaring?
				</p>
				<h3>Ga in gesprek met uw oudere werknemers</h3>
				<p>De oplossing ligt in het gesprek. Ga met uw (oudere) werknemers in
				gesprek over onderwerpen als ‘langer doorwerken’ en ‘met pensioen gaan’.
				Inventariseer met elkaar de wensen en mogelijkheden en zoek naar de
				beste optie. Wist u bijvoorbeeld dat vorig jaar bijna 46% van onze
				deelnemers het pensioen vóór hun AOW-leeftijd volledig heeft laten
				ingaan? En dat 2% met deeltijdpensioen is gegaan?</p>
				<h3>Met de Gesprekshulp bent u goed voorbereid</h3>
				<p>Met de Gesprekshulp gaat u (als leidinggevende, of als HR-medewerker)
				goed voorbereid deze gesprekken in. De app leidt u langs de
				belangrijkste overwegingen omtrent de vraag of een medewerker beter kan
				stoppen of doorgaan met werken. En als doorgaan de beste optie is, in
				welke vorm? Dankzij de Gesprekhulp komt u goed gefundeerd - samen met de
				werknemer - tot vervolgstappen.</p>
				<h3>Hoe werkt de Gesprekshulp?</h3>
				<p>
					U reageert op stellingen die allemaal betrekking hebben op drie
					mogelijke vervolgstappen: doorgaan met werken, anders werken en
					stoppen met werken. Deze stellingen worden gepresenteerd vanuit vier
					invalshoeken:
				</p>
				<ol>
					<li>De mening van de medewerker</li>
					<li>Uw eigen overwegingen</li>
					<li>Het werk van de medewerker</li>
					<li>Onze organisatie en afdeling</li>
				</ol>
				<p>
					De uitkomst is een ‘weging’ van de drie genoemde opties. U ziet nu
					welke keuze het meest voor de hand ligt: doorgaan, stoppen of anders
					werken.
				</p>
				<p>
					Als dat van toepassing is voor betreffende optie, dan krijgt u
					bovendien een gespreksleidraad; een overzicht van onderwerpen die u
					met uw medewerker kunt bespreken.
				</p>
				<div className="start">
					<Link to="/themas">
						<Button text="Start" icon="pijl" iconPosition="left" />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default IntroScreen;
