import React from 'react';

import './ResultTable.scss';

const ResultTable = (props) => {

	const { themas } = props.data;

	const calcThemeResult = (id, category) => {
		const hits = [];
		themas
			.filter(thema => thema.id === id)
			.map(thema => thema.straten
				.filter(straat => straat.category === category)
				.map(straat => straat.stegen
					.map(steeg => { if (steeg.score === 1) hits.push(steeg); return steeg;})));
		return hits.length;
	}

	const calcCategoryResult = category => {
		const hits = [];
		themas
			.map(thema => thema.straten
				.filter(straat => straat.category === category)
				.map(straat => straat.stegen
					.map(steeg => { if (steeg.score === 1) hits.push(steeg); return steeg;})));
		return hits.length;
	}

	const rows = themas.map(thema => (
		<tr key={"thema" + thema.id} className={"thema thema" + thema.id} >
			<td><b>{thema.naam}</b></td>
			<td>{calcThemeResult(thema.id, 'doorgaan')}</td>
			<td>{calcThemeResult(thema.id, 'anders')}</td>
			<td>{calcThemeResult(thema.id, 'stoppen')}</td>
		</tr>
		));

	return (
			   <table className="ResultTable">
			   	<thead>
			   		<tr>
			   			<td />
			   			<td>Doorgaan</td>
			   			<td>Anders werken</td>
			   			<td>Stoppen</td>
		   			</tr>
			   	</thead>
			   	<tbody>
			   	{rows}
			   	<tr className="divider" />
					<tr className="totals">
						<td>Totaal</td>
						<td>{calcCategoryResult('doorgaan')}</td>
						<td>{calcCategoryResult('anders')}</td>
						<td>{calcCategoryResult('stoppen')}</td>
					</tr>
			   	</tbody>
			   </table>
	)
}

export default ResultTable;
