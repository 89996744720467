export default [{
    "id": "1",
    "naam": "De mening van de medewerker",
    "beschrijving": "U wilt met uw oudere medewerker in gesprek over de laatste fase van zijn carrière.",
    "straten": [{
        "id": "1-1",
        "kop": "De medewerker gaat het liefst zo door.",
        "category": "doorgaan",
        "stegen": [{
            "id": "1-1-1",
            "kop": "De medewerker wil zelf graag doorwerken, en vindt zijn werk leuk.",
        }, {
            "id": "1-1-2",
            "kop": "De medewerker wil fysiek en mentaal fit blijven en wil dus doorwerken.",
        }, {
            "id": "1-1-3",
            "kop": "Het is voor deze medewerker financieel noodzakelijk om door te werken.",
        }, {
            "id": "1-1-4",
            "kop": "De medewerker bevindt zich in een conflictsituatie en wil juist daarom alles in het werk stellen om te blijven.",
        }, {
            "id": "1-1-5",
            "kop": "De medewerker wil doorwerken om zijn voorwaardelijk pensioen niet te verliezen.",
        }, {
            "id": "1-1-6",
            "kop": "Vanwege zijn thuissituatie wil de medewerker doorwerken.",
        }]
    }, {
        "id": "1-2",
        "kop": "De medewerker wil op een andere manier werken.",
        "category": "anders",
        "stegen": [{
                "id": "1-2-1",
                "kop": "Geleidelijk afbouwen is voor deze medewerker een gezonde oplossing.",
            },
            {
                "id": "1-2-2",
                "kop": "De medewerker wil een andere invulling van het contract, zoals minder werken, demotie of generatiepact.",
            }, {
                "id": "1-2-3",
                "kop": "De medewerker wil een ander soort functie binnen de organisatie.",
            }, {
                "id": "1-2-4",
                "kop": "De medewerker ziet outplacement of detachering als een aantrekkelijke oplossing.",
            }, {
                "id": "1-2-5",
                "kop": "De medewerker wil zijn tijd deels besteden aan hobby's, kleinkinderen of zorgtaken.",
            }
        ]
    }, {
        "id": "1-3",
        "kop": "De medewerker wil zelf vertrekken.",
        "category": "stoppen",
        "stegen": [{
            "id": "1-3-1",
            "kop": "De medewerker kan gebruik maken van een aantrekkelijke regeling.",
        }, {
            "id": "1-3-2",
            "kop": "De medewerker wil stoppen vanwege zijn eigen gezondheid of dat van een familielid.",
        }, {
            "id": "1-3-3",
            "kop": "De medewerker kijkt uit naar zijn pensionering, zodat hij meer tijd kan besteden aan hobby's of kleinkinderen.",
        }, {
            "id": "1-3-4",
            "kop": "Deze medewerker is bereid te vertrekken, mits daar een aantrekkelijke vergoeding tegenover staat.",
        }]
    }]
}, {
    "id": "2",
    "naam": "Mijn eigen overwegingen",
    "straten": [{
        "id": "2-1",
        "kop": "Ik zie de medewerker liever doorwerken.",
        "category": "doorgaan",
        "stegen": [{
                "id": "2-1-1",
                "kop": "Ik kan het niet maken de medewerker weg te sturen, bijvoorbeeld vanwege zijn financiële situatie.",
            },
            {
                "id": "2-1-2",
                "kop": "De medewerker presteert onvoldoende, maar kan daar niets aan doen, dus ik wil hem daar niet op afrekenen.",
            }, {
                "id": "2-1-3",
                "kop": "Ik durf niet op vertrek aan te sturen; dit vind ik confronterend en ik zou me er impopulair mee maken.",
            }, {
                "id": "2-1-4",
                "kop": "Ik gun deze medewerker geen vertrekregeling, dit zou een beloning zijn voor slecht gedrag.",
            }, {
                "id": "2-1-5",
                "kop": "Deze medewerker is belangrijk voor mij, bijvoorbeeld als klankbord, vriend of als medestander.",
            }, {
                "id": "2-1-6",
                "kop": "Ik moet duurzame inzetbaarheid stimuleren, anders vertrekken straks meer oudere werknemers.",
            }
        ]
    }, {
        "id": "2-2",
        "kop": "Ik zoek het liefst een alternatief.",
        "category": "anders",
        "stegen": [{
            "id": "2-2-1",
            "kop": "Ik zie tussenoplossingen als minder werken, generatiepact of demotie als een positieve fase in een carrière.",
        }, {
            "id": "2-2-2",
            "kop": "Ik voel het als mijn morele plicht om mensen zo lang mogelijk aan de slag te houden.",
        }, {
            "id": "2-2-3",
            "kop": "Ik wil toegeven aan de behoefte van de werknemer om flexibeler te werken, ook om arbeidstekorten te voorkomen.",
        }]
    }, {
        "id": "2-3",
        "kop": "Als het aan mij ligt, vertrekt deze medewerker.",
        "category": "stoppen",
        "stegen": [{
            "id": "2-3-1",
            "kop": "De medewerker heeft altijd hard gewerkt en wil nu van zijn verdiende pensioen genieten; dat regel ik graag.",
        }, {
            "id": "2-3-2",
            "kop": "Ik heb er geen vertrouwen in dat deze medewerker nog door kan.",
        }, {
            "id": "2-3-3",
            "kop": "Ik geloof niet in flexibele werktijden of deeltijdwerken.",
        }, {
            "id": "2-3-4",
            "kop": "Onze karakters zijn onverenigbaar, we hebben regelmatig conflicten.",
        }, {
            "id": "2-3-5",
            "kop": "Gezien de geestelijke of lichamelijke toestand van de medewerker vind ik doorwerken niet verantwoord.",
        }]
    }]
}, {
    "id": "3",
    "naam": "Het werk van de medewerker",
    "straten": [{
        "id": "3-1",
        "kop": "Deze medewerker functioneert goed.",
        "category": "doorgaan",
        stegen: [{
            "id": "3-1-1",
            "kop": "De medewerker levert goed werk, zowel kwalitatief als kwantitatief.",
        }, {
            "id": "3-1-2",
            "kop": "De medewerker is gemotiveerd en enthousiast.",
        }, {
            "id": "3-1-3",
            "kop": "Deze medewerker is flexibel en kan goed mee in een veranderende omgeving.",
        }, {
            "id": "3-1-4",
            "kop": "De medewerker heeft specifieke kennis die ik niet kwijt wil.",
        }]
    }, {
        "id": "3-2",
        "kop": "Het is beter als deze medewerker ander of minder werk gaat doen of zich bijschoolt.",
        "category": "anders",
        stegen: [{
            "id": "3-2-1",
            "kop": "Het gaat nu niet goed, en ik verwacht dat de medewerker elders beter tot zijn recht komt.",
        }, {
            "id": "3-2-2",
            "kop": "Het werk wordt te zwaar, deze medewerker functioneert beter in deeltijd of in een lichtere functie.",
        }, {
            "id": "3-2-3",
            "kop": "Knelpunten in competenties en vaardigheden kunnen we oplossen met scholing.",
        }]
    }, {
        "id": "3-3",
        "kop": "Deze medewerker functioneert niet goed.",
        "category": "stoppen",
        stegen: [{
            "id": "3-3-1",
            "kop": "De competenties van deze medewerker zijn niet op niveau en met scholing is dat niet op te vangen.",
        }, {
            "id": "3-3-2",
            "kop": "De lichamelijke of geestelijke gesteldheid van deze medewerker sluit niet (meer) aan op het werk.",
        }, {
            "id": "3-3-3",
            "kop": "De medewerker is niet (meer) flexibel en wil of kan niet meegaan met de veranderende organisatie.",
        }, {
            "id": "3-3-4",
            "kop": "Het ontbreekt deze medewerker aan de instelling en motivatie om dit werk te kunnen doen.",
        }]
    }]
}, {
    "id": "4",
    "naam": "Onze afdeling en organisatie",
    "straten": [{
        "id": "4-1",
        "kop": "Het is beter voor de afdeling als deze medewerker blijft.",
        "category": "doorgaan",
        stegen: [{
            "id": "4-1-1",
            "kop": "De medewerker heeft een belangrijke inhoudelijke rol binnen het team.",
        }, {
            "id": "4-1-2",
            "kop": "De medewerker heeft een belangrijke sociale rol in het team, bijvoorbeeld als aanjager of relativerende factor.",
        }, {
            "id": "4-1-3",
            "kop": "We hebben deze medewerker op dit moment hard nodig vanwege een specifiek project.",
        }, {
            "id": "4-1-4",
            "kop": "Het is moeilijk om aan goede mensen te komen, dus ik laat de medewerker liever niet gaan.",
        }]
    }, {
        "id": "4-2",
        "kop": "Het is beter voor de afdeling als de medewerker anders gaat werken.",
        "category": "anders",
        stegen: [{
            "id": "4-2-1",
            "kop": "De medewerker kan anderen begeleiden of coachen, en dat is goed voor het team.",
        }, {
            "id": "4-2-2",
            "kop": "De medewerker zal productiever en gelukkiger zijn in een nieuwe rol, wat ook anderen in het team motiveert.",
        }, {
            "id": "4-2-3",
            "kop": "Door deeltijdpensioen, generatiepact of demotie verjongen we het team, behouden we de kennis van deze medewerker en voorkomen we ziekteverzuim.",
        }, {
            "id": "4-2-4",
            "kop": "Door deze medewerker in flexibele uren in deeltijd te laten werken, wordt het makkelijker om piekbelasting op te vangen.",
        }]
    }, {
        "id": "4-3",
        "kop": "Vertrek van deze medewerker is beter voor de afdeling.",
        "category": "stoppen",
        stegen: [{
            "id": "4-3-1",
            "kop": "Onze organisatie moet inkrimpen, en daarom komt het vertrek van een medewerker goed uit.",
        }, {
            "id": "4-3-2",
            "kop": "De afdeling heeft jong bloed nodig, dat maakt ons flexibeler en zorgt dat onze kennis weer up-to-date is.",
        }, {
            "id": "4-3-3",
            "kop": "Het kost de afdeling te veel tijd en moeite om deze medewerker (weer) bij het werk te betrekken.",
        }, {
            "id": "4-3-4",
            "kop": "Deze medewerker ligt vaak dwars of is geen teamspeler; conflicten kosten ons onnodig veel energie.",
        }, {
            "id": "4-3-5",
            "kop": "Vertrek van deze medewerker drukt niet op ons afdelingsbudget, bijvoorbeeld als de organisatie de vertrekregeling betaalt.",
        }, {
            "id": "4-3-6",
            "kop": "Een middenweg als demotie of deeltijdpensioen is geen optie, omdat dit te duur of niet te organiseren is.",
        }, {
            "id": "4-3-7",
            "kop": "Onze organisatie kan het vertrek van de medewerker voordeliger regelen met detachering of outplacement.",
        }, {
            "id": "4-3-8",
            "kop": "De medewerker doet werk dat voor de afdeling weinig toegevoegde waarde heeft.",
        }]
    },{
        "id": "4-4",
        "kop": "Voor de organisatie is het beter dat deze medewerker blijft.",
        "category": "doorgaan",
        stegen: [{
            "id": "4-4-1",
            "kop": "De kwaliteit van onze publieke functie komt in gevaar door de grote uitstroom van oudere medewerkers.",
        }, {
            "id": "4-4-2",
            "kop": "Een vertrekregeling voor deze medewerker is te duur, bijvoorbeeld door de strafheffing van de fiscus.",
        }, {
            "id": "4-4-3",
            "kop": "Binnen onze cultuur is aansturen op vertrek niet gepast.",
        }, {
            "id": "4-4-4",
            "kop": "Voor de persoonlijke duurzame inzetbaarheid van deze medewerker is het belangrijk dat hij doorwerkt.",
        }]
    }, {
        "id": "4-5",
        "kop": "Het is in het belang van de organisatie om een alternatief te vinden.",
        "category": "anders",
        stegen: [{
            "id": "4-5-1",
            "kop": "Door medewerkers regelmatig van taak of functie te laten wisselen en op te leiden, blijven ze tot hun pensioen goed inzetbaar.",
        }, {
            "id": "4-5-2",
            "kop": "Zoeken naar manieren om ouderen actief te houden is goed voor het imago van de publieke sector.",
        }, {
            "id": "4-5-3",
            "kop": "Deeltijdpensioen, een andere functie, bij- en omscholen zijn middelen om het ziekteverzuim te beperken.",
        }, {
            "id": "4-5-4",
            "kop": "Binnen onze organisatie kan deze medewerker zich in een andere rol nog nuttig(er) maken.",
        }, {
            "id": "4-5-5",
            "kop": "Het generatiepact past goed bij deze medewerker en dit is ook voor de organisatie gunstig.",
        }]
    }, {
        "id": "4-6",
        "kop": "Voor de organisatie is het beter als deze werknemer vertrekt.",
        "category": "stoppen",
        stegen: [{
            "id": "4-6-1",
            "kop": "De organisatie moet besparen op personeelskosten en gaat reorganiseren.",
        }, {
            "id": "4-6-2",
            "kop": "De medewerker brengt het imago van de organisatie of de kwaliteit van de publieke voorziening in gevaar.",
        }, {
            "id": "4-6-3",
            "kop": "De medewerker is duur door hoge salariskosten of bijvoorbeeld garantieregelingen; een vertrekregeling verdient zichzelf snel terug.",
        }, {
            "id": "4-6-4",
            "kop": "Deeltijdpensioen, demotie en andere instrumenten om deze medewerker actief te houden zijn hier onvoldoende voorhanden.",
        }]
    }]
}]
