import React from "react";
import { Link } from "react-router-dom";

import Header from "../../components/Header/Header";
import ShareButtons from "../../components/ShareButtons/ShareButtons";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import ResultTable from "../../components/ResultTable/ResultTable";

import "./ResultScreen.scss";

const ResultScreen = props => {
	return (
		<div className="resultscreen">
			<Header {...props} />
			<ShareButtons />
			<div className="wrapper">
				<h3>Uw resultaat</h3>
				<h6>
					Hieronder ziet u hoe vaak u een stelling belangrijk vond. De
					optellingen in de blauwe balk laten zien hoezeer het voor de hand ligt
					om door te gaan met werken, anders te werken, of om te stoppen. Hoe
					hoger het totaal, hoe sterker de onderbouwing van betreffende optie.
					Bepaal op basis hiervan wat u wilt doen.
				</h6>
			</div>

            <div className="results-wrapper">
				<ResultTable {...props} />

				<div className="button-grid">
					<Link to="/acties/doorgaan" className="one">
						<Button text="Doorgaan" designType="secondary" classes="action" />
					</Link>
					<Link to="/acties/anders" className="two">
						<Button text="Anders werken" designType="secondary" classes="action" />
					</Link>
					<Link to="/acties/stoppen" className="three">
						<Button text="Stoppen" designType="secondary" classes="action" />
					</Link>

                    <Link to="/themas" className="back">
                        <Button designType="primary-ghost" text="Terug naar het overzicht" icon="pijl" iconPosition="left" iconFlipped classes="back-button" />
                    </Link>
				</div>
            </div>
			<Modal
				teksten={props.data.teksten}
				open={props.data.modal}
				toggleModal={props.toggleModal}
			/>
		</div>
	);
};

export default ResultScreen;
