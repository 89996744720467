import React from 'react';

const LogoABP = () => (

<svg viewBox="0 0 87.85 34.5" className="logo-abp">
	<path d="M26.62.69A2.8,2.8,0,0,0,24.44,0C24.17.06,17.75.91,13,4.44c-4.18,3.1-6.4,8.82-6.64,9.47a1.72,1.72,0,0,0-.07.21l-.06.19H2.78a2.78,2.78,0,0,0,0,5.56H4.65L1.49,31A2.79,2.79,0,0,0,3.41,34.4a2.84,2.84,0,0,0,.76.1,2.77,2.77,0,0,0,2.67-2l3.58-12.62H22V31.73a2.78,2.78,0,0,0,5.55,0V2.78A2.78,2.78,0,0,0,26.62.69ZM22,14.31H12.24A15.67,15.67,0,0,1,16.3,8.9a16.78,16.78,0,0,1,4.22-2.18c.88-.17,1.34.14,1.49.92Z" fill="#0057A3"/>
	<path d="M48.42,17.2a20.21,20.21,0,0,0,5.39-4.37,17.76,17.76,0,0,0,4-9.92A2.77,2.77,0,0,0,55,0H35.9a2.78,2.78,0,0,0-2.78,2.78v29A2.78,2.78,0,0,0,35.9,34.5H55a2.77,2.77,0,0,0,2.78-2.89,17.41,17.41,0,0,0-4.09-9.79A24.56,24.56,0,0,0,48.42,17.2ZM38.68,5.55H49.4c1.56,0,2.1.48,1.35,2a9.29,9.29,0,0,1-1.11,1.59,15.35,15.35,0,0,1-10.33,5.14h-.39l-.24,0ZM49.75,29H38.68V19.83a3.57,3.57,0,0,0,.47,0h.35c.89,0,5.4.28,10,5.61a10.63,10.63,0,0,1,1.26,1.79C51.25,28.41,50.85,28.86,49.75,29Z" fill="#0057A3"/>
	<path d="M87.19,1a2.76,2.76,0,0,0-2.11-1H65.38A2.77,2.77,0,0,0,62.6,2.78V14.24a3.05,3.05,0,0,0-3.07,2.85,3.05,3.05,0,0,0,3.07,2.85V31.73a2.78,2.78,0,0,0,5.55,0V19.8a27.64,27.64,0,0,0,10.78-3.12c7.21-3.9,8.82-13.06,8.88-13.45A2.76,2.76,0,0,0,87.19,1ZM80.73,6.89a11.14,11.14,0,0,1-4.44,4.9,21.9,21.9,0,0,1-8.14,2.43V5.55h11C80.41,5.56,81,5.89,80.73,6.89Z" fill="#0057A3"/>
</svg>

);

export default LogoABP;
