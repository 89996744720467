import React from 'react';
import { Link } from 'react-router-dom';

import Info from '../Icons/Info';
import LogoABP from '../Icons/LogoABP';

import './Header.scss';

const Header = (props) => (
  <div className="header">
    <LogoABP />
  	<div className="center">
      <Link to='/'><h6 className='title'>Gesprekshulp: hoe houd ik mijn (oudere) werknemers duurzaam inzetbaar?</h6></Link>
    </div>
    <div className="infobutton" onClick={props.toggleModal}><Info /></div>
  </div>
)

export default Header;
