import React from 'react';

import './Box.scss';

const Box = (props) => {

	const { kop, className } = props;

	const boxClass = className ? "Box " + (className) : "Box";

	return (
		<div className={boxClass}>
            <h3>{kop}</h3>

            <div className="BoxContent">
                {props.children}
            </div>
		</div>
	)
}

export default Box;
