import React from "react";

import Steeg from "./Steeg";
import ArrowRight from "../Icons/ArrowRight";

import "./Straat.scss";

const Straat = props => {
	const { straat, open } = props;

	const handleClick = () => props.handleClick(straat.id);

	const stegen = straat.stegen.map(steeg => (
		<Steeg key={"steeg" + steeg.id} steeg={steeg} {...props} />
	));

	const status = open ? "open" : "closed";

	return (
		<div className={"straat straat" + straat.id}>
			<div className="border">
				<button
					onClick={handleClick}
					className={"pijl " + status}
				>
					<ArrowRight className="top" color="#009CBD" />
					<ArrowRight className="bottom" color="#009CBD" />
				</button>
			</div>
			<h6 onClick={handleClick}>{straat.kop}</h6>
			<div className={"content " + status}>
				<table>
					<tbody>
						{stegen}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Straat;
