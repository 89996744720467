import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../components/Header/Header";
import ShareButtons from "../../components/ShareButtons/ShareButtons";
import Modal from "../../components//Modal/Modal";
import Card from "../../components/Card/Card";
import Button from "../../components/Button/Button";

import "./CardScreen.scss";

class CardScreen extends Component {
	componentDidMount() {
		window.addEventListener("keydown", this.onKeyDown);
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.onKeyDown);
	}

	onKeyDown = evt => {
		const { checkTheme } = this.props;
		const keyDown = evt.key || evt.keyCode;
		if (keyDown === "c") [1, 2, 3, 4].map(theme => checkTheme(theme));
	};

	render() {
		const props = this.props;
		const { themas } = props.data;
		const cards = themas.map((thema, i) => (
			<Card key={"card" + i} thema={thema} {...props} />
		));

		const checked = themas.filter(thema => thema.checked);
		const greyedOut = checked.length < 4;

		return (
			<div className="cardscreen">
				<Header {...props} />
				<ShareButtons />
				<div className="wrapper">
					<h3>
						U wilt met uw medewerker in gesprek over de laatste fase van zijn
						carrière
					</h3>
					<h6>
						Klik op de kaartjes en beantwoord de vragen. Na het invullen van
						alle kaartjes ziet u wat de meest voor de hand liggende vervolgstap
						is: doorgaan met werken, stoppen met werken, of een tussenweg.
					</h6>
				</div>
				<div className="cards">
					{cards}
					<Link to="/resultaat" style={{ textDecoration: 'none' }} className="align-at-end">
						<Button
							style={{ width: '198px' }} // Card width (240px) - padding (42px)
							text="Wat kunt u doen?"
							icon="pijl"
							iconPosition="left"
							greyedOut={greyedOut}
						/>
					</Link>
				</div>
				<Modal
					teksten={props.data.teksten}
					open={props.data.modal}
					toggleModal={props.toggleModal}
				/>
			</div>
		);
	}
}

export default CardScreen;
