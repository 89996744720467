import React from 'react';

import Box from '../../components/Box/Box';

const Mail = (props) => {

    const { data, match } = props;
    const { themas, teksten } = data;
    const actie = match.params.actie || 'doorgaan';
    const { kop, tekst1, acties } = teksten[actie];

    const actiekaders = acties ? acties.map((actie,i) => <Box key={"kader"+i} kop={actie.kop} className="acties">{actie.tekst}</Box>) : null;

    let aantalwijken = 0;
    const resultaten = themas.map(thema => {

      let checkedStraten = 0;

      const straten = thema.straten.map(straat => {

        const checkedStegen = straat.stegen
          .filter(steeg => steeg.score === 1)
          .map(steeg => <p key={steeg.id}>{steeg.kop}</p>);

        if (checkedStegen.length > 0) checkedStraten++;

        return checkedStegen.length > 0 ?
          (<div className="BoxStraat" key={straat.id}>
            <h7>{straat.kop}</h7>
            {checkedStegen}
          </div>) : null;

      })

      if (checkedStraten > 0) aantalwijken++;

      return checkedStraten > 0
        ? <Box key={"thema"+thema.id} kop={thema.naam} tekst={''} className={"thema"+thema.id}>{straten}</Box>
        : null;
    })

    const tekst2 = aantalwijken > 0 ? teksten[actie].tekst2 : '';


// mail template based on
// https://github.com/leemunroe/responsive-html-email-template/blob/master/email.html

	return(
		<html>
		  <head>
		    <meta name="viewport" content="width=device-width" />
		    <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
				<style dangerouslySetInnerHTML={{__html: `
				      img {
				        border: none;
				        -ms-interpolation-mode: bicubic;
				        max-width: 100%; }
				      body {
				        background-color: #f6f6f6;
				        font-family: sans-serif;
				        -webkit-font-smoothing: antialiased;
				        font-size: 14px;
				        line-height: 1.4;
				        margin: 0;
				        padding: 0;
				        -ms-text-size-adjust: 100%;
				        -webkit-text-size-adjust: 100%; }
				      table {
				        border-collapse: separate;
				        mso-table-lspace: 0pt;
				        mso-table-rspace: 0pt;
				        width: 100%; }
				        table td {
				          font-family: sans-serif;
				          font-size: 14px;
				          vertical-align: top; }
				      .body {
				        background-color: #f6f6f6;
				        width: 100%; }
				      .container {
				        display: block;
				        Margin: 0 auto !important;
				        /* makes it centered */
				        max-width: 580px;
				        padding: 10px;
				        width: 580px; }
				      .content {
				        box-sizing: border-box;
				        display: block;
				        Margin: 0 auto;
				        max-width: 580px;
				        padding: 10px; }
				      .main {
				        background: #ffffff;
				        border-radius: 3px;
				        width: 100%; }
				      .wrapper {
				        box-sizing: border-box;
				        padding: 20px; }
				      .content-block {
				        padding-bottom: 10px;
				        padding-top: 10px;
				      }
				      .footer {
				        clear: both;
				        Margin-top: 10px;
				        text-align: center;
				        width: 100%; }
				        .footer td,
				        .footer p,
				        .footer span,
				        .footer a {
				          color: #999999;
				          font-size: 12px;
				          text-align: center; }
				      h1,
				      h2,
				      h3,
				      h4 {
				        color: #000000;
				        font-family: sans-serif;
				        font-weight: 400;
				        line-height: 1.4;
				        margin: 0;
				        Margin-bottom: 30px; }
				      h1 {
				        font-size: 35px;
				        font-weight: 300;
							}
				      p,
				      ul,
				      ol {
				        font-family: sans-serif;
				        font-size: 14px;
				        font-weight: normal;
				        margin: 0;
				        Margin-bottom: 15px; }
				        p li,
				        ul li,
				        ol li {
				          margin-left: 5px; }
				      a {
				        color: #3498db;
				        text-decoration: underline; }
				      .last {
				        margin-bottom: 0; }
				      .first {
				        margin-top: 0; }
				      .align-center {
				        text-align: center; }
				      .align-right {
				        text-align: right; }
				      .align-left {
				        text-align: left; }
				      .clear {
				        clear: both; }
				      .mt0 {
				        margin-top: 0; }
				      .mb0 {
				        margin-bottom: 0; }
				      .preheader {
				        color: transparent;
				        display: none;
				        height: 0;
				        max-height: 0;
				        max-width: 0;
				        opacity: 0;
				        overflow: hidden;
				        mso-hide: all;
				        visibility: hidden;
				        width: 0; }
				      @media only screen and (max-width: 620px) {
				        table[class=body] h1 {
				          font-size: 28px !important;
				          margin-bottom: 10px !important; }
				        table[class=body] p,
				        table[class=body] ul,
				        table[class=body] ol,
				        table[class=body] td,
				        table[class=body] span,
				        table[class=body] a {
				          font-size: 16px !important; }
				        table[class=body] .wrapper,
				        table[class=body] .article {
				          padding: 10px !important; }
				        table[class=body] .content {
				          padding: 0 !important; }
				        table[class=body] .container {
				          padding: 0 !important;
				          width: 100% !important; }
				        table[class=body] .main {
				          border-left-width: 0 !important;
				          border-radius: 0 !important;
				          border-right-width: 0 !important; }
				        table[class=body] .btn table {
				          width: 100% !important; }
				        table[class=body] .btn a {
				          width: 100% !important; }
				        table[class=body] .img-responsive {
				          height: auto !important;
				          max-width: 100% !important;
				          width: auto !important; }
				        }
				      @media all {
				        .ExternalClass { width: 100%; }
				        .ExternalClass,
				        .ExternalClass p,
				        .ExternalClass span,
				        .ExternalClass font,
				        .ExternalClass td,
				        .ExternalClass div { line-height: 100%; }
							}
								h1 {
						    	font-size: 18px;
						    	font-weight: 600;
						    	margin: 0;
						    	color: #0057A3;
						    }
						    h3 {
						    	font-size: 15px;
						    	font-weight: 600;
						    	margin: 18px 0px;
						    	color: #0057A3;
						    }
						    h4 {
						    	color: #0057A3;
						    }
								.Box {
										border: 2px solid #0057A3;
										border-radius: 9px;
										overflow: hidden;
										margin-bottom: 15px;
						        box-sizing: border-box;
						    }
								h5 {
									margin: 0;
									padding: 12px;
									background-color: #0057A3;
									color: #fff;
									font-size: 15px;
								}
								.BoxContent {
									padding: 6px 12px;
									background-color: #fff;
									color: #333;
									font-size: 15px;
								}
								h7 {
									font-weight: bold;
								}
								p {
									margin: 6px 0;
									padding: 0;
								}
								ul {
									margin: 6px 0;
									padding-left: 18px;
								}
								.BoxStraat {
									margin-bottom: 18px;
								}
							}

							.Box.acties {
								border: 2px solid #0057A3;
							}
							.Box.acties	h5 {
									background-color: #0057A3;
								}
							.Box.thema1 {
								border: 2px solid #EA7109
							}
							.Box.thema1 h5 {
									background-color: #EA7109;
								}
							.Box.thema2 {
								border: 2px solid #FDB900;
							}
							.Box.thema2 h5 {
									background-color: #FDB900;
								}
							.Box.thema3 {
								border: 2px solid #169BBD;
							}
							.Box.thema3 h5 {
									background-color: #169BBD;
								}
							.Box.thema4 {
								border: 2px solid #DB0A2D;
							}
							.Box.thema4 h5 {
									background-color: #DB0A2D;
								}

				    `}} />
				</head>
				  <body className="">
				    <table border="0" cellPadding="0" cellSpacing="0" className="body">
				    <tbody>
				      <tr>
				        <td>&nbsp;</td>
				        <td className="container">
				          <div className="content">
				            <span className="preheader">Denkhulp: hoe houd ik mijn (oudere) werknemer duurzaam inzetbaar?</span>
				            <table className="main">
				            <tbody>
				              <tr>
				                <td className="wrapper">
				                  <table border="0" cellPadding="0" cellSpacing="0">
				                  <tbody>
				                    <tr>
				                      <td>
				                        <h1>Denkhulp: hoe houd ik mijn (oudere) werknemer duurzaam inzetbaar?</h1>
											  		    <h3 className="kop">{kop}</h3>
											          <h4>{tekst1}</h4>
											          <div className="acties">{actiekaders}</div>
											          <h4>{tekst2}</h4>
											          <div className="resultaten">{resultaten}</div>
				                      </td>
				                    </tr>
				                    </tbody>
				                  </table>
				                </td>
				              </tr>
				              </tbody>
				            </table>
				          </div>
				        </td>
				        <td>&nbsp;</td>
				      </tr>
				      </tbody>
				    </table>
				  </body>
				</html>
	  )

}
export default Mail;
