import React from 'react';

const Vuilnisbak = ({ color = '#FFFFFF' }) => (
	<svg viewBox="0 0 44 44" className="icon vuilnisbak">
  <path d="M19.5,5.33h5A2.5,2.5,0,0,1,27,7.83h0a2.5,2.5,0,0,1-2.5,2.5h-5A2.5,2.5,0,0,1,17,7.83h0A2.5,2.5,0,0,1,19.5,5.33Z" stroke="none" fill={color} />
  <path d="M9.5,12h25A2.5,2.5,0,0,1,37,14.5h0A2.5,2.5,0,0,1,34.5,17H9.5A2.5,2.5,0,0,1,7,14.5H7A2.5,2.5,0,0,1,9.5,12Z" stroke="none" fill={color} />
  <path d="M10.33,18.67,12.9,37.25a1.67,1.67,0,0,0,1.67,1.42h14.9a1.66,1.66,0,0,0,1.66-1.42l2.54-18.58ZM17.1,36.16H17a.84.84,0,0,1-.83-.73L14.51,22.1a.83.83,0,0,1,.72-.93.84.84,0,0,1,.93.73l1.67,13.33A.84.84,0,0,1,17.1,36.16Zm5.73-.83a.83.83,0,1,1-1.66,0V22a.83.83,0,0,1,1.66,0Zm5,.11a.84.84,0,0,1-.83.73h-.1a.84.84,0,0,1-.73-.93L27.84,21.9a.85.85,0,0,1,.93-.73.83.83,0,0,1,.72.93Z" stroke="none" fill={color} />  </svg>
);

export default Vuilnisbak;
