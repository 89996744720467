import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import Button from '../../components/Button/Button';

import './SplashScreen.scss';

class Splash extends Component {

	componentDidMount() {
		this.props.resetScore();
	}

	render(){
		return(
		  <div className="splashscreen">
		    <h1>Gesprekshulp voor werkgevers</h1>
		    <h2>Hoe houd ik mijn (oudere) werknemers duurzaam inzetbaar?</h2>
		    <h6>Begin direct of lees eerst de inleiding.</h6>
			<div className='button-pair'>
				<Link to="/inleiding" style={{ textDecoration: 'none' }}>
					<Button designType='secondary-ghost' text='Inleiding' icon='pijl' iconPosition='left' />
				</Link>
				<Link to="/themas" style={{ textDecoration: 'none' }}>
					<Button designType='primary' text='Start' icon='pijl' iconPosition='left' />
				</Link>
			</div>
		  </div>
		)
	}
}
export default Splash;
