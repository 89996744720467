import React, {Component} from 'react';
import axios from 'axios';
import validator from 'email-validator';
import {renderToStaticMarkup} from 'react-dom/server';

import Mail from '../Mail/Mail';
import Button from '../Button/Button';

import './MailModal.scss';
import pdf from '../../pdf/apg-argumentenkaart-werkgevers.pdf';

//const MailModal = (props) => {
class MailModal extends Component {

	constructor(props) {
        super(props);
        this.state = {
            mail: "",
            melding: ""
        }
    }

    handleClick = () => {
        this.setState({ mail: "" });
        this.setState({ melding: "" })
        this.props.toggleMailModal();
    }

    handleChange = (event) => {
        this.setState({ mail: event.target.value })
    }

    handleFocus = () => {
        this.setState({ melding: "" })
    }

    mailResult = (event) => {
        event.preventDefault();
        event.stopPropagation()
        const urlApi = 'https://api.keuzehulppensioen.nl'
        const mail = this.state.mail;
        const sender = 'ABP Denkhulp Pensioen <no-reply@keuzehulppensioen.nl>';
        const subject = 'Hoe houd ik mijn (oudere) werknemer duurzaam inzetbaar?';
        const html = renderToStaticMarkup(<Mail {...this.props}  />);
        const attachment = 'https://werkgever.keuzehulppensioen.nl' + pdf;

        if (validator.validate(mail)) {
            this.setState({ geldig: true});
            this.setState({melding: "Er is een mail verstuurd naar " + mail})
            axios
            .post(urlApi, { mail, html, sender, subject, attachment })
            .then((response) => {
                //const result = JSON.parse(response.request.response);
            })
            .catch((err) => {
                //console.log(JSON.parse(err));
            })
        } else {
            this.setState({ melding: "Voer een geldig mailadres in" })
        }
    }

	render() {
		return (
			<div className={this.props.open ? "mailmodal open" : "mailmodal"}>

				<div className="overlay" onClick={this.handleClick} />

				<form className="form" onSubmit={this.mailResult}>
                    <span className="wrapper">
                        <input type="text" placeholder="Typ hier je e-mailadres" value={this.state.mail} onChange={this.handleChange} onFocus={this.handleFocus}/>
                        <Button text="Mail mijn overzicht" type="submit" icon="mail" />
                    </span>

                    <p className="melding">{this.state.melding}</p>
                </form>
            </div>
		)
	}
}
export default MailModal;
