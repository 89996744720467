import React from 'react';

import './Steeg.scss';

const Steeg = (props) => {

	const { steeg, data, updateScore } = props;

	const id = steeg.id;

	let huidigeScore;
	data.themas.map(thema => {
			return thema.straten.map(straat =>
				straat.stegen.map(steeg => {
					if (steeg.id === id) huidigeScore = steeg.score;
					return steeg;
				})
			)
	});

	const changeHandler = (event) => {
		const nieuweScore = +event.target.value === 1 ? 0 : 1;
		updateScore(steeg.id, nieuweScore);
	}

	return (
		<tr className="steeg">
			<td>{steeg.kop}</td>
			<td className="label">
				<label>
					<input type="checkbox" onChange={changeHandler} onClick={changeHandler} name={steeg.id} value={huidigeScore || 0} checked={huidigeScore === 1}/>
				</label>
				<span className={(huidigeScore === 1) ? "checkbox checked" : "checkbox"} />
			</td>
		</tr>
	)
}

export default Steeg;
