import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../components/Header/Header";
import ShareButtons from "../../components/ShareButtons/ShareButtons";
import Modal from "../../components/Modal/Modal";
import Harmonica from "../../components/Harmonica/Harmonica";
import Button from "../../components/Button/Button";

import "./OptionScreen.scss";

class OptionScreen extends Component {
	state = {};

	componentWillMount() {
		const { match } = this.props;
		const themaNummer = match.params.thema
			? +match.params.thema.replace("thema", "")
			: null;
		if (!themaNummer || themaNummer < 0 || themaNummer > 4)
			this.props.history.push("/themas");
		else this.setState({ themaNummer });
	}

	render() {
		const props = this.props;
		const { data, checkTheme } = props;
		const { themaNummer } = this.state;

		if (!themaNummer) return null;

		const themaData = data.themas.filter(thema => +thema.id === themaNummer)[0];
		const { naam } = themaData;

		const handleClick = () => checkTheme(themaNummer);

		return (
			<div className="optionscreen">
				<Header {...props} />
				<ShareButtons />
				<div className="wrapper">
					<h3>{naam}</h3>
					<h6 className="uitleg">
						Selecteer de stellingen die u belangrijk vindt en sla deze op.<br/>
						U kunt deze later altijd nog wijzigen.
					</h6>
					<Harmonica thema={themaData} {...props} />
					<Link to="/themas" className="link-span">
						<Button
							text="Opslaan & naar het overzicht"
							icon="pijl"
							iconPosition="left"
							clickHandler={handleClick}
						/>
					</Link>
				</div>
				<Modal teksten={props.data.teksten} open={props.data.modal} toggleModal={props.toggleModal} />
			</div>
		);
	}
}

export default OptionScreen;
