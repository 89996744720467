import React, {Component} from 'react';
import { Link } from "react-router-dom";

import Header from '../../components/Header/Header';
import ShareButtons from '../../components/ShareButtons/ShareButtons';
import Modal from '../../components/Modal/Modal';
import MailModal from '../../components/MailModal/MailModal';
import Button from '../../components/Button/Button';
import Box from '../../components/Box/Box';

import './ExitScreen.scss';

class ExitScreen extends Component {

    componentDidMount(){
        const { match, history } = this.props;
        const actie = match.params.actie;
        if(!actie || !(actie === 'doorgaan' || actie === 'anders' || actie === 'stoppen')) history.push('/themas');
    }

    showPrint = () => {
        window.print();
    }

	render() {
        const props = this.props;
        const { data, match } = props;
        const { themas, teksten } = data;
        const actie = match.params.actie || 'doorgaan';
        const { kop, tekst1, acties } = teksten[actie];

        const actiekaders = acties ? acties.map((actie,i) => <Box key={"kader"+i} kop={actie.kop} className="acties">{actie.tekst}</Box>) : null;

        let aantalwijken = 0;
        const resultaten = themas.map(thema => {

        let checkedStraten = 0;

        const straten = thema.straten.map(straat => {
            const checkedStegen = straat.stegen
            .filter(steeg => steeg.score === 1)
            .map(steeg => <p key={steeg.id}>{steeg.kop}</p>);

            if (checkedStegen.length > 0) checkedStraten++;

            return checkedStegen.length > 0 
                ?   (<div className="BoxStraat" key={straat.id}>
                        <h6 className='box-straat-kop'>{straat.kop}</h6>
                        {checkedStegen}
                    </div>) 
                : null;
        })

        if (checkedStraten > 0) aantalwijken++;

        return checkedStraten > 0
            ? <Box key={"thema"+thema.id} kop={thema.naam} tekst={''} className={"thema"+thema.id}>{straten}</Box>
            : null;
        })

        const tekst2 = aantalwijken > 0 ? teksten[actie].tekst2 : '';

        return (
            <div className="exitscreen">

                <Header {...props}  />
                <ShareButtons />

                <div className="wrapper">
                    <h3 className="kop">{kop}</h3>

                    <div className="links">
                        <h6>{tekst1}</h6>
                        <div className="acties">{actiekaders}</div>
                        <h6>{tekst2}</h6>
                        <div className="resultaten">{resultaten}</div>
                    </div>

                    <div className="buttons rechts">
                        <Button
                            text="Print resultaat"
                            icon="print"
                            iconPosition="left"
                            clickHandler={this.showPrint}
                            style={{ width: '240px', maxWidth: '60vw' }}
                        />
                        <Button
                            text="Mail resultaat"
                            icon="mail"
                            iconPosition="left"
                            clickHandler={props.toggleMailModal}
                            style={{ width: '240px', maxWidth: '60vw' }}
                        />
                        <Link to="/themas">
                            <Button
                                designType='secondary-ghost'
                                text="Wis resultaat"
                                icon="vuilnisbak"
                                iconPosition="left"
                                clickHandler={props.resetScore}
                                style={{ width: '240px', maxWidth: '60vw' }}
                            />
                        </Link>
                        <Link to="/themas">
                            <Button
                                designType='primary-ghost'
                                text="Terug naar het overzicht"
                                icon="pijl"
                                iconPosition="left"
                                iconFlipped
                                style={{ width: '240px', maxWidth: '60vw' }}
                            />
                        </Link>
                    </div>
                </div>

                <Modal teksten={props.data.teksten} open={props.data.modal} toggleModal={props.toggleModal} />
                <MailModal teksten={props.data.teksten} open={props.data.mailmodal} toggleMailModal={props.toggleMailModal} mailResult={this.mailResult} {...props} />
            </div>
        )
	}
}
export default ExitScreen;
